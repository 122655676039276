import { APIFilter } from '@/utils/api'

export default {
  async selectLoadPage (Vue, idcontrato) {
    const apiCalls = []
    const apiFilterContrato = new APIFilter()
      .addExact('idcontrato', idcontrato)
    apiCalls.push({ name: 'selectContrato', method: 'contrato.select', params: { filter: apiFilterContrato } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectContratoCuota (Vue, filter, search, sorter, page, idcontrato) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('idcontrato', idcontrato)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.contratoCuota.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'contratoCuota.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectContratoCuotaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idcontrato_cuota', pks)
    const resp = await Vue.$api.call('contratoCuota.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
